<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("profile.edit") }}
            </div>
          </template>

          <v-form @submit.prevent="updateProfile" ref="form">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="form.name"
                    :rules="rules.name"
                    :label="$t('name')"
                    @input="resetInputError('name')"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.email')"
                    class="purple-input"
                    v-model="form.email"
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-alert v-if="errorMessage" tile class="mb-3" type="error">{{
                    $t(errorMessage)
                  }}</v-alert>
                  <v-alert v-if="savedItem" tile type="success" class="mb-3">{{
                    $t("profile.User saved")
                  }}</v-alert>
                  <v-btn color="success" class="mr-0 float-right" type="submit">
                    {{ $t("profile.update") }}
                  </v-btn>

                  <update-password></update-password>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("2fa.title") }}
            </div>
          </template>

          <v-alert v-if="savedItem" tile type="success" class="my-4">{{
            $t(savedItem.message)
          }}</v-alert>

          <div v-if="unset">
            <TwoFaUnsetForm @unset2fa="startSetup" @itemSaved="onUnset" />
          </div>
          <div v-else-if="setup">
            <TwoFaForm @has2fa="startUnset" @itemSaved="onSet" />
          </div>
          <div v-else-if="!user.has2fa">
            <v-btn class="btn btn-primary" @click="startSetup">
              {{ $t("2fa.setup") }}
            </v-btn>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TwoFaForm from "@/components/2fa/2faForm.vue";
import TwoFaUnsetForm from "@/components/2fa/2faUnsetForm.vue";
import crudForm from "mixins/crudForm";
//import validate from "@/plugins/validate";
import UpdatePassword from "@/views/pages/admins/UserPasswordUpdate";
export default {
  components: {
    UpdatePassword,
    TwoFaForm,
    TwoFaUnsetForm,
  },
  mixins: [crudForm],
  created() {
    this.loadProfile();
  },
  data() {
    return {
      apiQuery: "/admins/",
      file: "",
      disabled: 1,
      changed: false,
      error: false,
      image_url: "/img/avatardefault.png",
      pwd_form: {},
      rules: {
        first_name: [
          () =>
            this.getInputError("first_name", {
              min: 2,
              max: 30,
              name: this.$t("profile.first_name"),
            }),
        ],
        last_name: [
          () =>
            this.getInputError("last_name", {
              min: 2,
              max: 30,
              name: this.$t("profile.last_name"),
            }),
        ],
      },
      user: JSON.parse(localStorage.getItem("loggedInUser")),
      setup: false,
      unset: false,
      savedItem: null,
    };
  },
  mounted() {
    if (this.user.has2fa) {
      this.unset = true;
    }
  },
  methods: {
    loadProfile(uri = "/profile") {
      this.get(uri).then((data) => {
        this.form = data;
        this.image_url = data.image_url
          ? "/storage/" + data.image_url
          : "/img/avatardefault.png";
      });
    },
    changeImage(data) {
      this.image_url = data;
    },
    updateProfile() {
      this.errorMessage = null;
      this.serverErrors = null;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.patch("/profile/update", this.form)
        .then((res) => {
          this.savedItem = res;
          setTimeout(() => {
            this.savedItem = null;
          }, 2500);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
    startSetup() {
      this.savedItem = null;
      this.setup = true;
      this.unset = false;
    },
    startUnset() {
      this.savedItem = null;
      this.setup = false;
      this.unset = true;
    },
    onSet(s) {
      this.savedItem = s;
      this.user = JSON.parse(localStorage.getItem("loggedInUser"));
      this.user.has2fa = true;
      localStorage.setItem("loggedInUser", JSON.stringify(this.user));
      this.setup = false;
    },
    onUnset(s) {
      this.savedItem = s;
      this.user = JSON.parse(localStorage.getItem("loggedInUser"));
      this.user.has2fa = false;
      localStorage.setItem("loggedInUser", JSON.stringify(this.user));
      this.unset = false;
    },
  },
};
</script>
