<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        class="mr-0"
        @click="resetForm"
        >{{ $t("reset.change") }}</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>{{ $t("reset.change") }}</v-toolbar>
        <v-form
          id="resetpasswordform"
          ref="formpwd"
          class="pa-2"
          @submit.prevent="updatePWD"
        >
          <v-card-text>
            <div class="text-h2">
              <v-text-field
                class="purple-input"
                name="password"
                v-model="form.password"
                :rules="rules.password"
                id="password"
                :label="$t('reset.old')"
                type="password"
                @input="resetInputError('password')"
              />
              <v-text-field
                class="purple-input"
                name="newpwd"
                :rules="rules.newpwd"
                v-model="form.newpwd"
                id="newpwd"
                :label="$t('reset.new')"
                type="password"
                @input="resetInputError('newpwd')"
              />
              <v-text-field
                class="purple-input"
                name="newpwd_confirmation"
                v-model="form.newpwd_confirmation"
                :rules="rules.newpwd_confirmation"
                id="newpwd2"
                :label="$t('reset.confirm')"
                type="password"
                @input="resetInputError('newpwd_confirmation')"
              />
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="dialog.value = false">{{ $t("Close") }}</v-btn>
            <v-btn type="submit" color="success" class="mr-0">{{
              $t("reset.change")
            }}</v-btn>
          </v-card-actions>
        </v-form>
        <v-alert tile v-if="changed" type="success" class="mb-0">{{
          $t("reset.success")
        }}</v-alert>
        <v-alert tile v-if="errorMessage" type="error" class="mb-0">{{
          $t(errorMessage)
        }}</v-alert>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
export default {
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/admins/",
      min: 8,
      dialog: false,
      changed: false,
      rules: {
        password: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("reset.old") }),
          (v) =>
            (v || "").length >= this.min ||
            this.$t("validate.min.string", { min: this.min }),
          () =>
            this.getInputError("password", {
              min: this.min,
              name: this.$t("reset.old"),
            }),
        ],
        newpwd: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("reset.new") }),
          (v) =>
            (v || "").length >= this.min ||
            this.$t("validate.min.string", { min: this.min }),
          () =>
            this.getInputError("newpwd", {
              min: this.min,
              name: this.$t("reset.new"),
            }),
        ],
        newpwd_confirmation: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("reset.confirm") }),
          (v) =>
            (!!v && v) === this.form.newpwd ||
            this.$t("reset.passwords dont match"),
          () =>
            this.getInputError("newpwd_confirmation", {
              name: this.$t("reset.confirm"),
            }),
        ],
      },
    };
  },

  methods: {
    resetForm() {
      this.form = {};
      this.form = {};
      this.changed = false;
      this.serverErrors = {};
      this.errorMessage = null;
    },
    updatePWD() {
      if (!this.$refs.formpwd.validate()) {
        return;
      }
      this.post("/profile/resetpwd", this.form)
        .then(() => {
          this.changed = true;
          window.setTimeout(() => {
            this.dialog = false;
            this.resetForm();
          }, 2500);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          if (error.message == "Wrong password") {
            this.serverErrors = { password: ["reset.Wrong password"] };
            this.errorMessage = "reset.Wrong password";
          }
          this.$refs.formpwd.validate();
        });
    },
  },
};
</script>
