<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
      <v-card-text>
        <v-container>
          <span v-html="$t('2fa.unset')"></span>
          <div style="max-width: 200px; margin-left: calc(50% - 100px)">
            <v-text-field
              ref="sixcode"
              v-model="form.code"
              outlined
              counter="6"
            ></v-text-field>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions class="p-4">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!savedItem"
          type="submit"
          :disabled="sending"
          color="green"
          >{{ $t("2fa.confirm") }}</v-btn
        >
      </v-card-actions>
    </v-form>

    <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
      errorMessage
    }}</v-alert>
    <v-alert v-if="savedItem" tile type="success" class="mb-0"
      >{{ $t(savedItem.message) }}
    </v-alert>
    <v-progress-linear
      v-if="sending"
      color="#033"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
  </div>
</template>

<script>
import crudMixin from "../../mixins/crudForm";

export default {
  mixins: [crudMixin],
  data() {
    return {
      size: 200,
      apiQuery: "/unset2fa",
    };
  },
  methods: {},
};
</script>
